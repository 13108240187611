<template>
  <a-modal
    title="修改"
    :visible="visible"
    :confirm-loading="confirmLoading"
    :centered="true"
    :dialogStyle="dialogStyle"
    width="40%"
    @ok="handleOk"
    @cancel="handleCancel"
  >
 <!--   <div class="modal-container scroll" ref="scrollDom" :style="getStyles()"> -->
      <!-- <vue-scroll :ops="$root.scrollOpsY" style="height:80%"> -->
        <div class="flex items-center m-b-sm">
          <span class="sp-title m-r-sm text-right">是否开启</span>
          <div class="flex-one">
            <a-switch v-model="isOpen" @change="openHandle" />
          </div>
        </div>
        <template v-if="type === 2">
          <div class="flex items-center m-b-xs">
            <span class="sp-title m-r-sm text-right">报警阈值</span>
            <div class="flex-one">
              <a-input-number
                v-model="videoThreshold.threTime"
                :min="1"
                :max="timeMax"
                :precision="0"
                class="m-r-xs"
                @change="onVideoTimeChange"
              />
              <a-select
                style="width:70px;"
                v-model="videoThreshold.type"
                @change="handleTimeTypeChange"
              >
                <a-select-option value="hour">
                  小时
                </a-select-option>
                <a-select-option value="min">
                  分钟
                </a-select-option>
              </a-select>
            </div>
          </div>
          <alert-tips />
          <div class="flex m-b-sm">
            <span class="sp-title m-r-sm text-right">提示信息</span>
            <div class="flex-one">
              <div class="flex items-center m-b-xs">
                <span class="m-r-sm text-right">定义01</span>
                <div class="flex-one">
                  <a-radio-group name="radioGroup" v-model="definateOne">
                    <a-radio :value="0">
                      摄像机名称
                    </a-radio>
                    <a-radio :value="1">
                      摄像机编号
                    </a-radio>
                  </a-radio-group>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="type === 3">
          <div class="flex items-center m-b-xs">
            <span class="sp-title m-r-sm text-right">报警阈值</span>
            <div class="flex-one">
              <a-input-number
                v-model="storeThreshold.threhold"
                :min="0"
                :max="100"
                :precision="2"
                class="m-r-xs"
                @change="onThreholdChange"
              />%
            </div>
          </div>
          <alert-tips />
        </template>
        <template v-if="type === 4">
          <div class="flex items-center m-b-xs">
            <span class="sp-title m-r-sm text-right">报警阈值</span>
            <div class="flex-one">
              <a-input-number
                v-model="roadThreshold.threhold"
                :min="0"
                :precision="2"
                class="m-r-xs"
                @change="onRoadThreholdChange"
              />KM
            </div>
          </div>
          <alert-tips />
        </template>
        <template v-if="type !== 1 && type !== 2">
          <div class="flex m-b-xs">
            <span class="sp-title m-r-sm text-right">提示信息</span>
            <div class="flex-one">
              <a-textarea v-model="textTips" :rows="4" />
            </div>
          </div>
        </template>
        <template v-if="type === 1">
          <div class="flex m-b-sm">
            <span class="sp-title m-r-sm text-right">异常提示</span>
            <div class="flex-one">
              <a-checkbox-group v-model="exceptCheck" @change="onExceptChange">
                <a-row>
                  <a-col :span="8" v-for="item in exceptionList" :key="item.id">
                    <a-checkbox :value="item.id">
                      {{ item.text }}
                    </a-checkbox>
                  </a-col>
                </a-row>
              </a-checkbox-group>
            </div>
          </div>
          <div class="flex m-b-xs">
            <span class="sp-title m-r-sm text-right">提示信息</span>
            <div class="flex-one">
              <a-textarea v-model="textTips" :rows="4" />
            </div>
          </div>
          <div class="flex m-b-sm" v-if="false">
            <span class="sp-title m-r-sm text-right">提示信息</span>
            <div class="flex-one">
              <div class="flex items-center m-b-xs">
                <span class="m-r-sm text-right">定义01</span>
                <div class="flex-one">
                  <a-radio-group name="radioGroup" v-model="definateOne">
                    <a-radio :value="0">
                      摄像机名称
                    </a-radio>
                    <a-radio :value="1">
                      摄像机编号
                    </a-radio>
                  </a-radio-group>
                </div>
              </div>
              <div class="flex items-center">
                <span class="m-r-sm text-right">定义02</span>
                <div class="flex-one">
                  <a-radio-group name="radioGroup" v-model="definateTwo">
                    <a-radio :value="0">
                      异常提示
                    </a-radio>
                    <a-radio :value="1">
                      上报日志
                    </a-radio>
                  </a-radio-group>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div
          class="flex items-center m-b-sm"
          style="height:32px;"
          v-if="type !== 2"
        >
          <span class="sp-title m-r-sm text-right">提示时间</span>
          <div class="flex-one">
            <a-checkbox v-model="instantCheck" @change="onInstantChange" v-if="type !== 1">
              实时发送
            </a-checkbox>
            <a-checkbox v-model="timeCheck" @change="ontimeChange" v-if="type !==1 ">
              定时发送
            </a-checkbox>
            <template v-if="timeCheck || type ===1">
              <a-select
                v-model="turnDateNewHour"
                @change="handleHourChange"
                class="m-r-xs"
                style="width:17%;"
              >
                <a-select-option
                  v-for="item in hourArr"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.text }}
                </a-select-option>
              </a-select>
              <span class="m-r-xs m-l-xs">时</span>
              <a-select
                v-model="turnDateNewMinute"
                @change="handleMinuteChange"
                style="width:17%;"
              >
                <a-select-option
                  v-for="item in minuteArr"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.text }}
                </a-select-option>
              </a-select>
              <span class="m-l-xs">分</span>
            </template>
          </div>
        </div>
        <div class="flex">
          <span class="sp-title m-r-sm text-right">提示方式</span>
          <div class="flex-one">
            <div class="flex m-b-xs f-h">
               <div class="f-tit">
                <a-checkbox v-model="websiteFlag">
                  站内信
                </a-checkbox>
               </div>
              <a-select
              mode="multiple"
              class="wd20"
              v-show="websiteFlag"
              placeholder="请选择用户"
              v-model="letterUserIds"
            >
              <a-select-option v-for="item in userList" :key="item.userId">
                {{ item.loginName }}
              </a-select-option>
            </a-select>
            </div>
            <div class="flex m-b-xs f-h">
              <div class="f-tit">
                <a-checkbox v-model="messageFlag">
                  短信
                </a-checkbox>
              </div>
              <a-select
              mode="multiple"
              class="wd20"
              v-show="messageFlag"
              placeholder="请选择用户"
              v-model="msgUserIds"
            >
              <a-select-option v-for="item in userList" :key="item.userId">
                {{ item.loginName }}
              </a-select-option>
            </a-select>
            </div>
            <div class="flex m-b-xs"> 
              <div class="f-tit">
                <a-checkbox v-model="wechatFlag">
                  微信
                </a-checkbox>
              </div>
              <div class="text-lightgray text-center" v-show="wechatFlag">
                <img src="~@/assets/wechat.png" width="100" height="100"/>
                <div class="m-t-xs">使用微信扫一扫快速加入推送团队 
                  <a-popover placement="top">
                    <template slot="content">
                      <p class="wd100">
                       在微信中同步接收通知：<br/>
                      第一步：微信搜索并关注：南京感动科技有限公司2 <br/>
                        第二步：登录企业微信APP，我--设置--新消息通知--仅在企业微信中接收消息--关闭会话消息，关闭应用消息  </p>
                    </template>
                    <i class="icon-help m-l-sm pointer ve-m in-block"></i>
                  </a-popover>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--<div class="flex m-b-sm">
          <span class="sp-title m-r-sm text-right">提示方式</span>
          <div class="flex-one">
            <a-checkbox-group
              v-model="checkTipWay"
              @change="onTipWayChange"
              class="full-width"
            >
              <a-row>
                <a-col :span="8" v-for="item in tipWayList" :key="item.id">
                  <a-checkbox :value="item.value">
                    {{ item.text }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </div>
        </div>
        <template v-if="checkTipWay.includes(1)">
          <div class="flex items-center m-b-sm">
            <span class="sp-title m-r-sm text-right">提示用户</span>
            <div class="flex-one flex" v-if="false">
              <div class="m-r-sm">
                <a-input
                  v-model="item.text"
                  class="m-b-sm"
                  v-for="(item, index) in messageList"
                  :key="index"
                />
              </div>
              <a-button @click="addMessage" v-show="false">添加</a-button>
            </div>
            <div class="flex-one items-center">
               <span class="sp-title m-r-sm text-right">提示用户</span>  
              <a-select
                mode="multiple"
                style="width:180px"
                placeholder="请选择用户"
                v-model="currentUser"
              >
                <a-select-option v-for="item in userList" :key="item.userId">
                  {{ item.loginName }}
                </a-select-option>
              </a-select>
            </div>
          </div>
        </template>
        <template v-if="checkTipWay.includes(2)">
          <div class="text-lightgray text-center">
            <img src="~@/assets/wechat.png" width="100" height="100"/>
            <p class="m-t-xs">使用微信扫一扫快速加入推送团队</p>
          </div>
          <div class="flex m-b-sm" v-if="false">
            <span class="sp-title m-r-sm text-right">微信</span>
            <div class="flex-one flex">
              <div class="m-r-sm">
                <a-input
                  v-model="item.text"
                  class="m-b-sm"
                  v-for="(item, index) in mailList"
                  :key="index"
                />
              </div>
              <a-button @click="addMail" v-show="false">添加</a-button>
            </div>
          </div>
        </template>
        <template v-if="type !== 1 && type !== 2 && false">
          <div class="flex m-b-sm items-center">
            <span class="sp-title m-r-sm text-right">提示用户</span>
            <a-select
              mode="multiple"
              class="full-width"
              placeholder="请选择用户"
              v-model="currentUser"
            >
              <a-select-option v-for="item in userList" :key="item.userId">
                {{ item.loginName }}
              </a-select-option>
            </a-select>
          </div>
        </template>-->
      <!-- </vue-scroll> -->
    <!--  </div> -->
  </a-modal>
</template>

<script>
import { getTimes } from '@/tools/';
import { CODE_OK } from '@/request/config_code';
import AlertTips from './AlertTips';
import moment from 'moment';
export default {
  name: 'ModifySetting',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: 0,
    },
    currentRecord: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      websiteFlag:false,
      messageFlag:false,
      wechatFlag:false,
      msgUserIds:[],
      letterUserIds:[],
      confirmLoading: false,
      isOpen: true,
      exceptCheck: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      exceptionList: [
        {
          id: 0,
          text: '画面闪烁',
          value: '1',
        },
        {
          id: 1,
          text: '画面噪声',
          value: '1',
        },
        {
          id: 2,
          text: '画面模糊',
          value: '1',
        },
        {
          id: 3,
          text: '网络异常',
          value: '1',
        },
        {
          id: 4,
          text: '画面黑屏',
          value: '1',
        },
        {
          id: 5,
          text: '画面滚动条纹',
          value: '1',
        },
        {
          id: 6,
          text: '画面亮度异常',
          value: '1',
        },
        {
          id: 7,
          text: '异物遮挡',
          value: '1',
        },
        {
          id: 8,
          text: '画面静帧',
          value: '1',
        },
      ],
      definateOne: 0,
      definateTwo: 0,
      instantCheck: false,
      timeCheck: false,
      turnDateNewHour: '08', //'08',
      turnDateNewMinute: '40', //'40',
      hourArr: (() => {
        return getTimes(24);
      })(),
      minuteArr: (() => {
        return getTimes(60);
      })(),
      tipWayList: [
        {
          id: 0,
          text: '站内信',
          value: 0,
        },
        {
          id: 1,
          text: '短信',
          value: 1,
        },
       /*  {
          id: 2,
          text: '邮箱',
          value: 2,
        }, */
         {
          id: 2,
          text: '微信',
          value: 2,
        },
      ],
      checkTipWay: [],
      videoThreshold: {
        threTime: 1,
        type: 'hour',
      },
      storeThreshold: {
        threhold: 0,
      },
      roadThreshold: {
        threhold: 0,
      },
      textTips: '',
      userList: [],
      currentUser: [],
      messageList: [
        {
          text: '',
        },
      ],
      mailList: [
        {
          text: '',
        },
      ],
      textSysTips:'【${1}】尊敬的用户，截止当前时间，系统检测到${2}${3}${4}的催办任务信息，请尽快处理【${4}】!',
      dialogStyle: { maxHeight: `${window.innerHeight - 20}px`, height:'auto',minHeight:'250px' },
      // getStyles:{maxHeight:`${window.innerHeight - 20}px`,height:'100%',minHeight:'250px'}
      // getStyles: {maxHeight:`${window.innerHeight - 20}px`}
    };
  },
  computed: {
    timeMax() {
      return this.videoThreshold.type == 'hour' ? 2 : 120;
    },
  },
  created() {
    this.initTextTip(this.type);
    this._getUserList();
  },
  watch: {
    type(nv, ov) {
      if (nv !== ov) {
        this.initTextTip(nv);
      }
    },
    visible(nv, ov) {
      if (nv && nv !== ov) {
        this._getUserList();
        this.initData();
      }
    },
    websiteFlag(nv) {
      this.letterUserIds = nv ? this.letterUserIds : []
    },
    messageFlag(nv) {
      this.msgUserIds = nv ? this.msgUserIds : []
    }
  },
  methods: {
    getStyles(){
      let dom = this.$refs['scrollDom'];
      if(dom) {
        return {
          height:`${dom.getBoundingClientRect().height}px`,
          maxHeight:`${window.innerHeight - 20}px`
        }
      }else{
        return { 
          height:'100%',
          maxHeight:`${window.innerHeight - 20}px`
        }
      }
    },
    initData() {
      this.isOpen = this.currentRecord.alarmOnState === 1 ? true : false;
      this.definateOne = this.currentRecord.customInformationOne;
      this.definateTwo = this.currentRecord.customInformationTwo;
      this.instantCheck =
        this.currentRecord.alarmTimeImmediatelyType === 1 ? true : false;
      this.timeCheck =
        this.currentRecord.alarmTimeTimingType === 1 ? true : false;

      this.setTimeCheckFormat(this.currentRecord.alarmTimeTiming);
      this.setCheckMessageType(this.currentRecord);
      this.setShortMessagePhone(this.currentRecord.shortMessagePhone);
      this.setWechatName(this.currentRecord.wechatName);
      this.setExceptCheck(this.currentRecord.exceptionType);
      this.setThreshold(this.currentRecord.threshold);
      // this.currentUser = this.currentRecord.ids ? this.currentRecord.ids : [];fix以前
      this.msgUserIds = this.currentRecord.msgUserIds;
      this.letterUserIds = this.currentRecord.letterUserIds;
      this.textTips = this.currentRecord.alarmInformation;
    },
    setThreshold(threshold) {
      if (this.type == 2) {
        if (threshold == 60 || threshold == 120) {
          this.threshold.threTime = Math.round(threshold / 60);
          this.videoThreshold.type = 'hour';
        } else {
          this.videoThreshold.threTime = threshold;
          this.videoThreshold.type = 'min';
        }
      } else if (this.type == 3) {
        this.storeThreshold.threhold = threshold;
      } else if (this.type == 4) {
        this.roadThreshold.threhold = threshold;
      }
    },
    setExceptCheck(except) {
      let exceptArr = except.split('');
      let ids = [];
      exceptArr.forEach((item, index) => {
        if (item == '1') {
          ids.push(index);
        }
      });
      this.exceptCheck = ids.map((item) => Number(item));
      this.exceptCheckReset = [];
      [0, 1, 2, 3, 4, 5, 6, 7, 8].forEach((item, index) => {
        this.exceptCheckReset[index] = 0;
        this.exceptCheck.forEach((cv) => {
          if (index === cv) {
            this.exceptCheckReset[index] = 1;
          }
        });
      });
    },
    setShortMessagePhone(txt) {
      this.$set(this.messageList[0], 'text', txt);
    },
    setWechatName(txt) {
      this.$set(this.mailList[0], 'text', txt);
    },
    setTimeCheckFormat(date) {
      let dateArr = date.split(':');
      this.turnDateNewHour = dateArr[0]; //'08',
      this.turnDateNewMinute = dateArr[1]; //'40',
    },
    setCheckMessageType(record) {
      let alarmPromptMode = record.alarmPromptMode;
      let alarmPromptModeShort = record.alarmPromptModeShort;
      let alarmPromptModeWechat = record.alarmPromptModeWechat;
      if (alarmPromptMode === 1) {
        this.checkTipWay.push(0);
      }
      if (alarmPromptModeShort === 1) {
        this.checkTipWay.push(1);
      }
      if (alarmPromptModeWechat === 1) {
        this.checkTipWay.push(2);
      }
      //以前的可以忽略，现在的新增
      this.websiteFlag = record.alarmPromptMode === 1 ? true:false;
      this.wechatFlag = record.alarmPromptModeWechat === 1 ? true:false;
      this.messageFlag = record.alarmPromptModeShort === 1 ? true:false;
    },
    _getUserList() {
      this.$api.cameraGroup.getUserList().then((res) => {
        if (res.code === CODE_OK) {
          this.userList = res.data;
        }
      });
    },
    initTextTip(type) {
      this.textTips =
        type === 1? this.textSysTips:
        type === 3
          ? '您的存储空间余量不足，请及时处理~'
          : type === 4
          ? '告警：管辖区域内存在拥堵路段，请及时处理！'
          : type === 5
          ? '告警：管辖区域内存在离线网关，请及时关注！'
          : '';
    },
    //是否开启操做
    openHandle(checked) {
      this.isOpen = checked;
    },
    //异常类型
    onExceptChange(checkedValues) {
      let newArr = new Array(9).fill(-1);
      this.exceptCheckReset = [];
      newArr.forEach((item, index) => {
        this.exceptCheckReset[index] = 0;
        checkedValues.forEach((cv) => {
          if (index === cv) {
            this.exceptCheckReset[index] = 1;
          }
        });
      });
      this.exceptCheck = checkedValues;
    },
    //提示时间
    onInstantChange(e) {
      this.instantCheck = e.target.checked;
    },
    ontimeChange(e) {
      this.timeCheck = e.target.checked;
      /*   this.turnDateNewHour = '08';
      this.turnDateNewMinute = '40'; */
    },
    handleHourChange(value) {
      this.turnDateNewHour = value;
    },
    handleMinuteChange(value) {
      this.turnDateNewMinute = value;
    },
    onTipWayChange(checkedValues) {
      this.checkTipWay = checkedValues;
    },
    onVideoTimeChange(value) {
      this.videoThreshold.threTime = value;
    },
    handleTimeTypeChange(value) {
      this.videoThreshold.type = value;
    },
    onThreholdChange(value) {
      this.storeThreshold.threhold = value;
    },
    onRoadThreholdChange(value) {
      this.roadThreshold.threhold = value;
    },
    handleOk() {
      // this.exceptCheckReset = this.exceptCheck
      let obj = {
        alarmOnState: this.isOpen ? 1 : 0,
        alarmPromptMode:this.websiteFlag?1:0,
        alarmPromptModeShort:this.messageFlag?1:0,
        alarmPromptModeWechat:this.wechatFlag?1:0,
        // alarmPromptMode: this.checkTipWay.includes(0) ? 1 : 0,
        // alarmPromptModeShort: this.checkTipWay.includes(1) ? 1 : 0,
        // alarmPromptModeWechat: this.checkTipWay.includes(2) ? 1 : 0,
        alarmTimeImmediately: this.instantCheck
          ? moment().format('YYYY-MM-DD HH:mm:ss')
          : '',
        alarmTimeImmediatelyType: this.instantCheck ? 1 : 0,
        alarmTimeTiming: this.timeCheck
          ? `${this.turnDateNewHour}:${this.turnDateNewMinute}`
          : '',
        alarmTimeTimingType: this.timeCheck ? 1 : 0,
        customInformationOne: this.definateOne,
        customInformationTwo: this.definateTwo,
        exceptionType: this.exceptCheckReset.join(''),
        id: this.currentRecord.id,
        // ids: this.currentUser,fix以前
        msgUserIds: this.msgUserIds,
        letterUserIds: this.letterUserIds,
        shortMessagePhone: this.checkTipWay.includes(1)
          ? this.messageList[0].text
          : '',
        threshold:
          this.type === 2
            ? this.videoThreshold.type == 'hour'
              ? parseInt(this.videoThreshold.threTime) * 60
              : this.videoThreshold.threTime
            : this.type === 3
            ? this.storeThreshold.threhold
            : this.type === 4
            ? this.roadThreshold.threhold
            : 0,
        wechatName: this.checkTipWay.includes(2) ? this.mailList[0].text : '',
        alarmInformation:this.textTips
      };
      // eslint-disable-next-line no-unused-vars
      let regPhone = /^1[3|4|5|7|8][0-9]{9}$/;
      // eslint-disable-next-line no-unused-vars
      let regMail = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
     /* if (obj.shortMessagePhone && !regPhone.test(obj.shortMessagePhone)) {
        this.$message.error('手机号码不合法！');
        return;
      }
       if (obj.wechatName && !regMail.test(obj.wechatName)) {
        this.$message.error('邮箱不合法！');
        return;
      } fixsxy
       if (obj.wechatName === '') {
        this.$message.error('微信号不能为空！');
        return;
      }*/
      this.$api.alarm.modifyAlarm(obj).then((res) => {
        if (res.code === CODE_OK) {
          this.$message.success('修改成功！');
          this.resetData();
          this.$emit('sure');
        }
      });
    },
    handleCancel() {
      this.resetData();
      this.$emit('close');
    },
    addMessage() {
      let obj = {
        text: '',
      };
      this.messageList.push(obj);
    },
    addMail() {
      let obj = {
        text: '',
      };
      this.mailList.push(obj);
    },
    resetData() {
      this.isOpen = true;
      this.exceptCheck = [0, 1, 2, 3, 4, 5, 6, 7, 8];
      this.exceptCheckReset = [];
      this.instantCheck = false;
      this.timeCheck = false;
      this.definateOne = 0;
      this.definateTwo = 0;
      this.turnDateNewHour = '08';
      this.turnDateNewMinute = '40';
      this.checkTipWay = [];
      this.videoThreshold = {
        threTime: 1,
        type: 'hour',
      };
      this.storeThreshold = {
        threhold: 0,
      };
      this.roadThreshold = {
        threhold: 0,
      };
      this.textTips = '';
      this.currentUser = [];
      this.messageList = [
        {
          text: '',
        },
      ];
      this.mailList = [
        {
          text: '',
        },
      ];
    },
  },
  components: {
    AlertTips,
  },
};
</script>

<style scoped>
.sp-title {
  flex: 0 0 14%;
  width: 14%;
}
.p-l-title {
  padding-left: 14%;
}
.modal-container {
  height:100%;
}
.wd20{
  width: 50%;
}
.f-tit{
  width: 90px;
}
.f-h{
  min-height:34px;
}
::v-deep .ant-modal-content,::v-deep .ant-modal-body{
  height:100%;
}
.wd100{
  width: 320px;
}
.scroll{
  overflow: auto;
}
</style>
