<template>
  <a-table
    :rowKey="(row) => row.id"
    :components="resibleTableHeader"
    :columns="columns"
    :data-source="list"
    :pagination="false"
    :scroll="scroll"
    bordered
  >
    <template slot="customAlarmTimeTimingType" slot-scope="text, records">
      {{ text === 0 ? records.alarmTimeImmediately : records.alarmTimeTiming }}
    </template>
    <!-- <template slot="customAlarmPromptMode" slot-scope="text,records">
      {{ records === 0 ? '站内信' : text === 1 ? '短信' : '微信' }}
 
    </template> -->
    <!--  <template slot="customStatus" slot-scope="text, records">
      <a-switch
        :checked="text == 1 ? true : false"
        @change="onSwitch($event, records)"
      /> 
    </template>-->
    <template slot="customOpe" slot-scope="text, record, index">
      <a-tooltip placement="top">
        <template slot="title">
          <span>编辑</span>
        </template>
        <a-icon
          type="edit"
          class="m-r-sm pointer"
          @click="editHandle(record, index)"
        />
      </a-tooltip>
    </template>
  </a-table>
</template>

<script>
import ResizableTable from '@/components/mixins/resizeableTable';
export default {
  name: 'AlarmSetting',
  mixins:[ResizableTable],
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
     
      columns: [
        {
          title: '序号',
          dataIndex: 'id',
          width: 40,
          ellipsis: true,
        },
        {
          title: '报警类型',
          dataIndex: 'alarmType',
          width:70
        },
        {
          title: '报警内容',
          dataIndex: 'alarmContent',
          width: 70,
          ellipsis: true,
        },
        {
          title: '触发条件',
          dataIndex: 'alarmCondition',
          width: 140,
          ellipsis: true,
        },
        {
          title: '提示信息',
          dataIndex: 'alarmInformation',
          width: 160,
          ellipsis: true,
        },
        {
          title: '告警时间',
          dataIndex: 'alarmTimeTimingType',
          width: 80,
          ellipsis: true,
          scopedSlots: { customRender: 'customAlarmTimeTimingType' },
        },
        {
          title: '提示用户',
          dataIndex: 'userNumber',
          width: 60,
          ellipsis: true,
        },
        {
          title: '提示方式',
          dataIndex: 'alarmPromptWay',
          width: 140,
          ellipsis: true,
          /*  scopedSlots: {
            customRender: 'customAlarmPromptMode',
          }, */
        },
        {
          title: '是否开启',
          dataIndex: 'alarmOnState',
          /*  scopedSlots: {
            customRender: 'customStatus',
          }, */
          customRender: (text) => {
            return text === 1 ? '开启' : '关闭';
          },
          width: 60,
        },
        {
          title: '操作',
          dataIndex: 'ope',
          scopedSlots: {
            customRender: 'customOpe',
          },
          width: 60,
          className:'column-center'
        },
      ],
      scroll: {y:'100%',x:'100%' },
    };
  },
  computed: {},
  watch: {},

  methods: {
    onSwitch($event, records) {
      records.alarmOnState = $event ? 1 : 0;
    },
    editHandle(record, index) {
      // console.log(record);
      this.$emit('edit', { index: index + 1, record });
    },
  },
};
</script>

<style scoped></style>
