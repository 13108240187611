<template>
  <div class="full-width full-height">
    <tab :tabs="tabs" :initTab="initTab" @select="selectHandle" />
    <div class="m-t-sm flex">
      <div class="fl-menu flex flex-column m-b-md items-center">
        <span
          :class="[{ current: activeMenu === 0 }, 'pointer m-b-sm']"
          @click="tabClick(0)"
          >报警设置</span
        >
        <span
          :class="[{ current: activeMenu === 1 }, 'pointer']"
          @click="tabClick(1)"
          >LOGO与名称</span
        >
      </div>
      <div class="f-r m-l-md">
        <vue-scroll :ops="$root.scrollOpsY" :style="getHeight()">
          <alarm-setting
            v-if="activeMenu === 0"
            :list="data"
            @edit="editRecord"
          ></alarm-setting>
          <logo-setting v-else></logo-setting>
        </vue-scroll>
      </div>
    </div>
    <modify-setting
      :type="currentType"
      :currentRecord="currentRecord"
      :visible="visibleSetting"
      @sure="sureHandle"
      @close="closeHandle"
    />
  </div>
</template>

<script>
import Tab from '@/components/Tab';
import tabMixin from '@/components/mixins/tabs';
import AlarmSetting from './components/AlarmSetting';
import LogoSetting from './components/LogoSetting';
import ModifySetting from './components/ModifySetting';
import { CODE_OK } from '@/request/config_code';
export default {
  name: 'SystemSetting',
  mixins: [tabMixin],
  data() {
    return {
      activeMenu: 0,

      data: [],
      visibleSetting: false,
      currentType: 5,
      currentRecord: {},
    };
  },
  components: {
    Tab,
    AlarmSetting,
    LogoSetting,
    ModifySetting,
  },
  computed: {},
  watch: {},
  created() {
    this._getAlarmList();
  },
  methods: {
    _getAlarmList() {
      this.$api.alarm.getAlarm().then((res) => {
        if (res.code === CODE_OK) {
          this.data = res.data.map((item) => {
            let arr = [];
            arr[0] = item.alarmPromptMode === 1 ? '站内信' : '';
            arr[1] = item.alarmPromptModeShort === 1 ? '短信' : '';
            arr[3] = item.alarmPromptModeWechat === 1 ? '邮箱' : '';
            arr = arr.filter((ar) => !!ar);
            item.alarmPromptWay = arr.join(',');
            return item;
          });
        }
      });
    },
    selectHandle(index) {
      this.selectCurrent(index);
    },
    tabClick(index) {
      this.activeMenu = index;
    },
    getHeight() {
      let winHeight = window.innerHeight;
      return { height: `${winHeight - 180}px` };
    },
    closeHandle() {
      this.visibleSetting = false;
      this.currentType = -1;
      this.currentRecord = {};
    },
    sureHandle() {
      this._getAlarmList();
      this.closeHandle();
    },
    editRecord(obj) {
      this.currentType = obj.index;
      this.currentRecord = obj.record;
      this.visibleSetting = true;
    },
  },
};
</script>

<style scoped>
.f-r{
  width: calc(100% - 120px);
}
</style>
