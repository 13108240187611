<template>
  <div class="text-orange p-l-title m-l-sm m-b-sm">
    到达该阈值后，系统将自动发送告警信息。
  </div>
</template>

<script>
export default {
  name: 'AlertTip',
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped></style>
