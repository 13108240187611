<template>
  <div>
    <div class="m-b-md">
      <div class="flex m-b-sm items-center">
        <span class="wd-title text-white flex-s text-right m-r-xs"
          ><em class="text-red">*</em>平台名称：</span
        >
        <div class="flex items-center flex-one">
          <a-input
            placeholder="请输入平台名称"
            class="m-r-sm search-wd-5 search-date-picker"
            v-model="platformName"
            :maxLength="20"
          />
          <span class="text-white"
            >{{ platformName ? platformName.length : 0 }}/20</span
          >
        </div>
      </div>
      <div class="flex m-b-sm items-center">
        <span class="wd-title text-white flex-s text-right m-r-xs"
          ><em class="text-red">*</em>上传LOGO:</span
        >
        <div class="flex-one">
          <a-upload
            name="avatar"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :before-upload="beforeUpload"
            @change="handleChange"
          >
            <img v-if="imageUrl" :src="imageUrl" alt="avatar" class="img-width"/>
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
            </div>
          </a-upload>
          <span class="block text-white"
            >支持jpeg、png格式，1M以内，推荐50px*50px</span
          >
        </div>
      </div>
      <div class="flex">
        <span class="wd-title text-white flex-s text-right m-r-xs">&nbsp;</span>
        <a-button type="primary" @click="handleOk">确定</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState,mapMutations,mapActions} from 'vuex'
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
export default {
  name: 'LogoSetting',
  data() {
    return {
      disabled: false,
      platformName: '',
      loading: false,
      imageUrl: '',
    };
  },
  computed: {
    ...mapState({
      logoInfo:(state) => state.login.logoInfo,
    })
  },
  watch: {
   /*  userInfo:{
      handler(){
        this.platformName = this.userInfo.webTitle;
        this.imageUrl = this.userInfo.logoUrl;
      },
      deep:true
    } */
  },
  created() {
    this.platformName = this.logoInfo.name;
    this.imageUrl = this.logoInfo.logoUrl;
  },
  methods: {
    ...mapMutations(['setUserInfo']),
    ...mapActions(['logout','getLogoInfo']),
    handleDisabledChange(disabled) {
      this.disabled = disabled;
    },
    handleChange(info) {
      /* if (info.file.status === 'uploading') {
        this.loading = true;
      } */
      /* if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (imageUrl) => {
          this.imageUrl = imageUrl;
          this.loading = false;
        });
      } */
      this.loading = true;
      getBase64(info.file.originFileObj, (imageUrl) => {
        this.imageUrl = imageUrl;
        this.loading = false;
      });
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
    },
    handleOk() {
      const reg = /^[A-Za-z0-9-_.\u4e00-\u9fa5]{1,20}$/;
      if (!reg.test(this.platformName)) {
        this.$message.error(
          '系统名称只能是[1-20位]汉字、数字、大小写字母、“_”、“.” 组合！'
        );
        return;
      }
      if (!this.imageUrl) {
        this.$message.error('请添加图片');
        return;
      }
      let params = new FormData();
      const imgFile = this.dataURLtoBlob(this.imageUrl);
      params.append('file', imgFile);
      params.append('name', this.platformName);
      this.$api.aiCenterY.setLogo(params).then((res) => {
        if (res.code === 200) {
          this.$message.success('保存成功');
          /* this.$confirm({
            title: '提示',
            content: '退出登陆后才能看见更新，确认退出登录吗?',
            onOk: () => {
              this.logout();
            },
            onCancel: () => {},
          }); */
          this.getLogoInfo();
          // let {name,logoUrl} = res.data;
          // this.setUserInfo({...this.userInfo,...{webTitle:name,logoUrl}}); 
        }
      });
    },
    dataURLtoBlob(dataurl) {
      if (dataurl.split(',')[0].match(/:(.*?);/)) {
        var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      } else {
        if (!dataurl.trim()) {
          this.$message({
            type: 'error',
            message: 'LOGO不能为空！',
          });
        }
      }
    },
  },
};
</script>

<style scoped>
::v-deep .ant-upload{
  width: 200px;
  height: 200px;
}

.img-width{
  max-width: 100%;
}
</style>
