import { render, staticRenderFns } from "./ModifySetting.vue?vue&type=template&id=9cf22ec6&scoped=true&"
import script from "./ModifySetting.vue?vue&type=script&lang=js&"
export * from "./ModifySetting.vue?vue&type=script&lang=js&"
import style0 from "./ModifySetting.vue?vue&type=style&index=0&id=9cf22ec6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9cf22ec6",
  null
  
)

export default component.exports